import $ from 'jquery';
import { urlParams, csrfToken } from 'common/http';
import { assign } from 'common/window_location';

import { createMeasureView } from 'common/measures_editor/lib/coreServer';

let inProgress = false;

const titleTextBox = $('#asset-title-input');
const getAssetTitle = () => titleTextBox.val();

function updateButtonDisplay() {
  const busyBtn = $('#create-spinner');
  const disabledBtn = $('#create-text-disabled');
  const readyBtn = $('#create-text');
  const errorMessage = $('#error-message');

  busyBtn.hide();
  disabledBtn.hide();
  readyBtn.hide();

  if (inProgress) {
    busyBtn.show();
    errorMessage.hide();
  } else if (getAssetTitle() != '') {
    readyBtn.show();
  } else {
    disabledBtn.show();
  }
}

function handleError(xhr, textStatus, errorThrown, fromApi, reason) {
  // TODO: airbrake this
  console.error(
    'An error occurred while making a request to ' + fromApi + '.',
    xhr,
    textStatus,
    errorThrown
  );
  const message = $('#error-message');
  message.show();
  if (reason) {
    message.text(reason);
  }
  inProgress = false;

  updateButtonDisplay();
}

function handleRevisionError(
  view,
  xhr,
  textStatus,
  errorThrown,
  headers,
  fromApi
) {
  $.ajax({
    type: 'DELETE',
    url: '/api/views/' + view.id,
    headers: headers
  });

  handleError(xhr, textStatus, errorThrown, fromApi);
}

function createAsset(evt) {
  evt.preventDefault();

  switch (window.socrata.assetType) {
    case 'measure': createMeasure(); break;
    case 'dataset': createDataset(); break;
    default: console.error(`Unknown asset type: ${window.assetType}`);
  }
}

function createMeasure() {
  inProgress = true;
  updateButtonDisplay();

  createMeasureView(getAssetTitle()).
    then(({ id }) => assign(`/d/${id}`));
}

function createDataset() {
  const isDataAsset = 'data_asset' in urlParams() ? urlParams().data_asset.toLowerCase() === 'true' : null;

  const datasetTitle = getAssetTitle();
  const revisionData = {
    action: {type: 'replace'},
    is_parent: isDataAsset,
    creation_source: 'browser'
  };

  if (inProgress || datasetTitle == '') return false;
  inProgress = true;
  updateButtonDisplay();
  const headers = {
    'X-CSRF-Token': csrfToken(),
    'X-App-Token': window.serverConfig.appToken
  };
  const deletedAt = urlParams().deleted_at;
  const deletedAtParam = (deletedAt ? '?deleted_at=' + deletedAt : '');

  $.ajax({
    type: 'POST',
    url: `/api/views${deletedAtParam}`,
    headers: headers,
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      displayType: 'draft',
      name: datasetTitle,
      metadata: { isParent: isDataAsset}
    }),
    success: function(newView) {
      const locale = document.location.pathname.match(/^\/[a-zA-Z]{2}\//);

      $.ajax({
        type: 'POST',
        url: '/api/publishing/v1/revision/' + newView.id,
        headers: headers,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify(revisionData),
        success: function() {
          document.location =
            (locale ? locale[0] : '/') + 'd/' + newView.id + '/revisions/0';
        },
        error: function(xhr, textStatus, errorThrown) {
          handleRevisionError(
            newView,
            xhr,
            textStatus,
            errorThrown,
            headers,
            'dsmapi'
          );
        }
      });
    },
    error: function(xhr, textStatus, errorThrown) {
      handleError(xhr, textStatus, errorThrown, 'core', xhr.responseJSON.message);
    }
  });
}

function closeModal() {
  if (document.referrer.includes(window.location.host)) {
    window.history.back();
  } else {
    assign('/profile');
  }
}

const form = $('#asset-form');
form.on('submit', createAsset);

titleTextBox.on('input', updateButtonDisplay);

const cancelBtn = $('#cancel-btn');
cancelBtn.on('click', closeModal);

updateButtonDisplay();
